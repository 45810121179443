import React, { createContext, useState } from "react";
import axios from "axios";
import { apiKey } from "../api/config";
export const MovieContext = createContext();

const MovieContextProvider = props => {
	const [movies, setMovies] = useState([]);
	const [loading, setLoading] = useState(true);
	const runSearch = query => {
		axios
			.get(
				`https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${apiKey}&tags=${query}&per_page=24&format=json&nojsoncallback=1`
			)
			.then(response => {
				setMovies(response.data.photos.photo);
				setLoading(false);
				console.log('API Success', response);
			})
			.catch(error => {
				console.log('API Error', error);
			});
	};

	return (
		<MovieContext.Provider value={{ movies, loading, runSearch }}>
			{props.children}
		</MovieContext.Provider>
	);
};

export default MovieContextProvider;
