import React from "react";
import Form from "./Form";
import Navigation from "./Navigation";
import logo from '../logo.svg';

const Header = ({ history, handleSubmit }) => {
	return (
		<header className="App-header">
			<img src={logo} className="App-logo" alt="logo" />
			<h1>Moviizer</h1>
			<Form history={history} handleSubmit={handleSubmit} />
			<Navigation />
		</header>
	);
};

export default Header;
