import React, { useContext, useEffect } from "react";
import { MovieContext } from "../context/MovieContext";
import Gallery from "./Gallery";
import Loader from "./Loader";

const Container = ({ searchTerm }) => {
	const { movies, loading, runSearch } = useContext(MovieContext);
	useEffect(() => {
		runSearch(searchTerm);
	}, [searchTerm]);

	return (
		<div className="movie-container">
			{loading ? <Loader /> : <Gallery data={movies} />}
		</div>
	);
};

export default Container;
