import React, { Component } from "react";
import MovieContextProvider from "./context/MovieContext";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Search from "./components/Search";
import NotFound from "./components/NotFound";
import './App.css';


class App extends Component {

	handleSubmit = (e, history, searchInput) => {
		e.preventDefault();
		e.currentTarget.reset();
		let url = `/search/${searchInput}`;
		history.push(url);
	};

	render() {
		console.log(Date());
		return (
			<div className="App">
				<MovieContextProvider>
					<HashRouter basename="/">
						<Route
							render={props => (
								<Header
									handleSubmit={this.handleSubmit}
									history={props.history}
								/>
							)}
						/>
						<Switch>
							<Route
								exact
								path="/"
								render={() => <Redirect to="/movieTest" />}
							/>
							<Route path="/route1" render={() => <Search searchTerm="route1" />} />
							<Route path="/route2" render={() => <Search searchTerm="route2" />} />
							<Route path="/route3" render={() => <Search searchTerm="route3" />} />
							<Route
								path="/search/:searchInput"
								render={props => (
									<Search searchTerm={props.match.params.searchInput} />
								)}
							/>
							<Route component={NotFound} />
						</Switch>
					</HashRouter>
				</MovieContextProvider>
			</div>
		);
	}
}

export default App;
