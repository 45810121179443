import React from "react";
import Container from "./Container";

const Search = ({ searchTerm }) => {
	return (
		<div class="App-body">
			<h2>Search Results for: {searchTerm}</h2>
			<Container searchTerm={searchTerm} />
		</div>
	);
};

export default Search;
