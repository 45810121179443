import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="main-nav">
      <ul>
        <li><NavLink to="/route1">Route 1</NavLink></li>
        <li><NavLink to="/route2">Route 2</NavLink></li>
        <li><NavLink to="/route3">Route 3</NavLink></li>
      </ul>
    </nav>
  );
}

export default Navigation;
