import React from "react";
import NoMovies from "./NoMovies";
import Movie from "./Movie";

const Gallery = props => {
	const results = props.data;
	let images;
	let noMovies;

	if (results.length > 0) {
		images = results.map(image => {
			let farm = image.farm;
			let server = image.server;
			let id = image.id;
			let secret = image.secret;
			let title = image.title;
			let url = `https://farm${farm}.staticflickr.com/${server}/${id}_${secret}_m.jpg`;
			return <Movie url={url} key={id} alt={title} />;
		});
	} else {
		noMovies = <NoMovies />;
	}

	return (
		<div>
			<ul>{images}</ul>
			{noMovies}
		</div>
	);
};

export default Gallery;
